import { AxiosError } from 'axios'

const handleAxiosError = (error: unknown): string => {
  if (error instanceof AxiosError) {
    const errorData = error.response?.data

    if (errorData?.errors) {
      if (typeof errorData.errors === 'string') {
        return errorData.errors
      }

      if (typeof errorData.errors === 'object') {
        return Object.values(errorData.errors).flat().join('\n')
      }
    }

    if (errorData?.error) {
      return errorData.error
    }
  }

  return 'An unknown error occurred'
}

export default handleAxiosError
