import { FC } from 'react'
import { LinkButtonProps } from './types'
import { linkButton } from './config'
import { Spinner } from '../spinner'

export const LinkButton: FC<LinkButtonProps> = ({
  className,
  intent,
  size,
  href,
  children,
  onClick,
  isLoading = false,
  badgeContent,
}) => {
  const badgeClass =
    typeof badgeContent === 'string' && badgeContent.startsWith('-')
      ? 'bg-red-500 text-white'
      : 'bg-white text-[#242424] border border-gray-300'

  const badgeWidthClass =
    badgeContent && badgeContent.toString().length > 2
      ? 'min-w-[65px] px-1'
      : 'min-w-[22px]'

  const badgeLeftClass =
    badgeContent && badgeContent.toString().length > 2
      ? 'left-[73px]'
      : 'left-[118px]'

  return (
    <a
      href={href}
      className={linkButton({ intent, size, className })}
      style={{ position: 'relative' }}
      onClick={(event) => {
        if (onClick) {
          if (event.metaKey || event.ctrlKey) return
          event.preventDefault()
          onClick(event)
        }
      }}
    >
      {isLoading ? <Spinner /> : null}
      {children}

      {badgeContent && (
        <span
          className={`absolute bottom-[33px] ${badgeLeftClass} flex items-center justify-center ${badgeClass} ${badgeWidthClass} text-[11px] h-[20px] rounded-full whitespace-nowrap`}
        >
          {badgeContent}
        </span>
      )}
    </a>
  )
}
