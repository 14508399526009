import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { apiService } from '../../api-base'
import {
  Kvants,
  AvailabilitesProduct,
  ProductOption,
  KvantPayload,
  KvantData,
  EditKvantPayload,
} from './types'

export class KvantsService {
  static getKvants(
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<Kvants[]>> {
    return apiService.get<AxiosResponse<Kvants[]>>(`/kvants`, config)
  }

  static getAvailabilitiesProduct(config?: {
    storeId: string
    limit: number
  }): Promise<AxiosResponse<AvailabilitesProduct[]>> {
    return apiService.get<AxiosResponse<AvailabilitesProduct[]>>(
      `/products/availabilities`,
      { params: { ...config, withoutInventories: 1 } },
    )
  }

  static getProductOption(
    id: string,
  ): Promise<AxiosResponse<{ id: string; options: ProductOption[] }>> {
    return apiService.get<
      AxiosResponse<{ id: string; options: ProductOption[] }>
    >(`/products/available/${id}`)
  }

  static getKvantBySlug(id: string): Promise<AxiosResponse<KvantData>> {
    return apiService.get<AxiosResponse<KvantData>>(`/kvants/${id}`)
  }

  static getVariation(
    id: string,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse> {
    return apiService.get<AxiosResponse>(
      `/products/available/${id}/variation`,

      { ...config },
    )
  }

  static createNewKvant(data: KvantPayload): Promise<AxiosResponse> {
    return apiService.post<AxiosResponse>(`/kvants`, data)
  }

  static updateKvant(
    data: EditKvantPayload,
    id: string,
  ): Promise<AxiosResponse> {
    return apiService.put<AxiosResponse>(`/kvants/${id}`, data)
  }
}
