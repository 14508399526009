import { cva } from 'class-variance-authority'

export const linkButton = cva(
  ['border rounded-lg', 'active:bg-opacity-80', 'flex items-center gap-2'],
  {
    variants: {
      intent: {
        mm: 'bg-[#9370db]  text-[#242424]  hover:bg-violet-200 disabled:opacity-50 disabled:hover:bg-violet-300',
        wildberries:
          'bg-[#3A0078]  text-white border-transparent,  hover:bg-[#612C99] disabled:opacity-50 disabled:hover:bg-[#2B005A] ',
        ozon: 'bg-blue-600 text-white border-transparent hover:bg-blue-500 disabled:opacity-50 disabled:hover:bg-blue-600',
        cdek: ' bg-green-600 text-white border border-transparent hover:bg-green-500 disabled:hover:bg-green-600',
        saferoute:
          'bg-[#242424] text-white  hover:bg-gray-600 disabled:opacity-50 disabled:hover:bg-gray-300',
        epackage:
          'bg-gray-600 text-white  hover:bg-gray-400 disabled:opacity-50 disabled:hover:bg-gray-300',
        russianpostru:
          'bg-white text-[#242424]  hover:bg-gray-200 disabled:opacity-50 disabled:hover:bg-gray-200',
        ems: 'bg-gray-400 text-white border-transparent hover:bg-gray-500 disabled:opacity-50 disabled:hover:bg-gray-600',
        pickup:
          'bg-blue-400 text-white border-transparent hover:bg-blue-500 disabled:opacity-50 disabled:hover:bg-blue-600',
        dhl: 'bg-[#FFCC00] text-[#242424]  border-transparent  hover:bg-[#F6DB71] disabled:opacity-50 disabled:hover:bg-yellow-400',

        secondary:
          'bg-white text-gray-800 border-gray-300 hover:bg-gray-200 disabled:hover:bg-white  rounded-lg',
      },
      size: {
        medium: ['text-base', 'py-2', 'px-4'],
      },
    },
    compoundVariants: [
      {
        intent: 'secondary',
        size: 'medium',
      },
    ],
    defaultVariants: {
      intent: 'secondary',
      size: 'medium',
    },
  },
)
