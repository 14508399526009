/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeAutoObservable, runInAction } from 'mobx'
import { handleAxiosError } from '~/shared/lib'
import { KvantsService } from '~/shared/api/services/kvants'
import { FormData, Loaders } from './types'
import {
  AvailabilitesProduct,
  KvantData,
  Kvants,
  ProductOption,
} from '~/shared/api/services/kvants/types'
import { SelectedOption } from '~/shared/api/services/warehouses/types'
import { SelectOption } from '~/shared/ui'

export class KvantsStore {
  loaders: Loaders = {
    kvants: false,
    availabilitiesProduct: false,
    productOptions: false,
    variations: false,
    createNewKvant: false,
    kvantBySlug: false,
    update: false,
    loadMoreKvants: false,
  }

  formData: FormData = {
    name: '',
    shop: null,
    product: '',
    quantity: '',
    vendorCode: '',
    barcode: '',
    description: '',
    weight: '',
    length: '',
    width: '',
    height: '',
  }

  kvantBySlug: KvantData | null = null

  availabilitiesProduct: AvailabilitesProduct[] = []

  selectedOptions: SelectedOption[] = []

  productOptions: ProductOption[] | null = null

  requestSettings = {
    limit: 20,
    offset: 0,
    isAllLoaded: false,
  }

  kvants: Kvants[] = []

  constructor() {
    makeAutoObservable(this)
  }

  activeLoader = (key: keyof Loaders) => {
    this.loaders = { ...this.loaders, [key]: true }
  }

  closeLoader = (key: keyof Loaders) => {
    this.loaders = { ...this.loaders, [key]: false }
  }

  getKvants = async (action?: 'loadMoreKvants') => {
    if (action === 'loadMoreKvants' && this.requestSettings.isAllLoaded) return
    this.activeLoader(action || 'kvants')
    try {
      const params = {
        limit: this.requestSettings.limit,
        offset: action !== 'loadMoreKvants' ? 0 : this.requestSettings.offset,
      }

      const { data } = await KvantsService.getKvants({
        params,
      })

      if (data.length !== 0) {
        if (action === 'loadMoreKvants') {
          runInAction(() => {
            this.kvants = [...this.kvants, ...data]
          })
        } else {
          runInAction(() => {
            this.kvants = data
          })
        }
        this.requestSettings = {
          ...this.requestSettings,
          isAllLoaded: data.length < this.requestSettings.limit,
          offset: action
            ? this.requestSettings.offset + this.requestSettings.limit
            : 20,
        }
      }
    } catch (error) {
      const errorMessage = handleAxiosError(error)
      throw errorMessage as any
    } finally {
      this.closeLoader(action || 'kvants')
    }
  }

  getAvailabilitiesProduct = async (storeId: string) => {
    try {
      this.activeLoader('availabilitiesProduct')
      const { data } = await KvantsService.getAvailabilitiesProduct({
        storeId,
        limit: 20,
      })
      runInAction(() => {
        this.availabilitiesProduct = data
      })
    } catch (error) {
      const errorMessage = handleAxiosError(error)
      throw errorMessage as any
    } finally {
      runInAction(() => {
        this.closeLoader('availabilitiesProduct')
      })
    }
  }

  getProductOption = async (productId: string) => {
    try {
      this.activeLoader('productOptions')
      const { data } = await KvantsService.getProductOption(productId)
      runInAction(() => {
        this.productOptions = data.options
      })
    } catch (error) {
      const errorMessage = handleAxiosError(error)
      throw errorMessage as any
    } finally {
      runInAction(() => {
        this.closeLoader('productOptions')
      })
    }
  }

  getKvantBySlug = async (id: string) => {
    try {
      this.activeLoader('kvantBySlug')
      const { data } = await KvantsService.getKvantBySlug(id)
      runInAction(() => {
        this.kvantBySlug = data
      })
    } catch (error) {
      const errorMessage = handleAxiosError(error)
      throw errorMessage as any
    } finally {
      runInAction(() => {
        this.closeLoader('kvantBySlug')
      })
    }
  }

  getVariation = async (productId: string) => {
    try {
      this.activeLoader('variations')
      const { data } = await KvantsService.getVariation(productId, {
        params: { optionValues: this.selectedOptions.map((e) => e.value) },
      })
      return data.id
    } catch (error) {
      const errorMessage = handleAxiosError(error)
      throw errorMessage as any
    } finally {
      runInAction(() => {
        this.activeLoader('variations')
      })
    }
  }

  setFormData = (name: keyof FormData, value: SelectOption | string | null) => {
    this.formData[name] = value as SelectOption | (string & SelectOption)
  }

  setSelectedOptions = (obj: SelectedOption) => {
    const existingIndex = this.selectedOptions.findIndex(
      (opt) => opt.optionID === obj.optionID,
    )

    if (existingIndex > -1) {
      this.selectedOptions = this.selectedOptions.map((el, ind) =>
        ind === existingIndex ? obj : el,
      )
    } else {
      this.selectedOptions = [...this.selectedOptions, obj]
    }
  }

  createNewKvant = async (product_variation_id: string) => {
    try {
      this.activeLoader('createNewKvant')
      const store = this.formData.shop as SelectOption
      await KvantsService.createNewKvant({
        product_variation_id,
        store_id: store.value,
        name: this.formData.name as string,
        count: Number(this.formData.quantity),
        description: this.formData.description as string,
        vendor_code: this.formData.vendorCode as string,
        sku: this.formData.barcode as string,
        width: Number(this.formData.width),
        height: Number(this.formData.height),
        weight: Number(this.formData.weight),
        length: Number(this.formData.length),
        archived_at: null,
        price: null,
        sort_order: null,
      })
    } catch (errors) {
      const errorMessage = handleAxiosError(errors)
      throw errorMessage as any
    } finally {
      this.closeLoader('createNewKvant')
    }
  }

  resetOptions = () => {
    this.selectedOptions = []
  }

  setFormDataEdit = (name: string, value: string) => {
    runInAction(() => {
      if (this.kvantBySlug) {
        this.kvantBySlug = {
          ...this.kvantBySlug,
          [name]: value,
        }
      }
    })
  }

  kvantUpdate = async (id: string) => {
    try {
      this.activeLoader('update')

      const updateKvant = {
        name: this.kvantBySlug?.name || '',
        description: this.kvantBySlug?.description || '',
        vendor_code: this.kvantBySlug?.vendor_code || '',
        sku: this.kvantBySlug?.sku || '',
        width: Number(this.kvantBySlug?.width),
        height: Number(this.kvantBySlug?.height),
        weight: Number(this.kvantBySlug?.weight),
        length: Number(this.kvantBySlug?.length),
        count: Number(this.kvantBySlug?.count),
      }

      await KvantsService.updateKvant(updateKvant, id)
    } catch (errors) {
      const errorMessage = handleAxiosError(errors)
      throw errorMessage as any
    } finally {
      this.closeLoader('update')
    }
  }

  clearFormData = () => {
    this.formData = {
      name: '',
      shop: null,
      product: '',
      quantity: '',
      vendorCode: '',
      description: '',
      barcode: '',
      weight: '',
      length: '',
      width: '',
      height: '',
    }
  }
}

export default KvantsStore
