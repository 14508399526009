import { createContext, useContext } from 'react'
import { AuthModel } from '~/entities/auth'
import { CategoriesModel } from '~/entities/category-list'
import { ProductsModel } from '~/entities/products'
import { StoresModel } from '~/entities/stores/model'
import { OrdersModel } from '~/entities/orders/model'
import { WarehousesModel } from '~/entities/warehouses/model'
import { RegistrationModel } from '~/entities/registration'
import MarketingStore from '~/entities/marketing/model'
import MainModel from '~/entities/main'
import { GlobalModel } from '~/shared/store/global-store'
import { DiscountStore } from '~/entities/marketing/discount/model'
import { CouponStore } from '~/entities/marketing/coupon/model'
import SliderStore from '~/entities/marketing/slider/model'
import UsersStore from '~/entities/users/model'
import { RetailStore } from '~/entities/retail-filter/model'
import { DeliveryStore } from '~/entities/delivery/model'
import KvantsStore from '~/entities/kvants'

class Store {
  globalStore = new GlobalModel()

  registrationStore = new RegistrationModel()

  authStore = new AuthModel()

  mainStore = new MainModel()

  productsStore = new ProductsModel()

  categoriesStore = new CategoriesModel()

  storesStore = new StoresModel()

  ordersStore = new OrdersModel()

  warehousesStore = new WarehousesModel()

  marketingStore = new MarketingStore()

  discountStore = new DiscountStore()

  couponsStore = new CouponStore()

  sliderStore = new SliderStore()

  usersStore = new UsersStore()

  retailStore = new RetailStore()

  deliveryStore = new DeliveryStore()

  kvantsStore = new KvantsStore()
}

export const store = new Store()

export const StoreContext = createContext(store)

export const StoreProvider = ({ children }: { children: React.ReactNode }) => (
  <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
)

export const useStore = () => useContext(StoreContext)
